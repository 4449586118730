@import url("https://fonts.googleapis.com/css2?family=Merriweather+Sans:ital,wght@0,300..800;1,300..800&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

:root {
  --COLOR_VERYLIGHT: #d5f0c1;
  --COLOR_LIGHT: #aad9bb;
  --COLOR_MEDIUM: #80bcbd;
  --COLOR_DARK: #003f3f;
}

.App {
  text-align: center;
}

.App-header {
  min-height: 100dvh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  font-size: 3.5svh;
  color: white;
}

.Task {
  width: 80%;
  height: 77svh;
  border-radius: 1.5svh;
  box-shadow: 0.3svh 0.3svh 0.6svh rgba(0, 0, 0, 0.5);
}

.shippy {
  position: absolute;
  top: 7dvh;
  left: 88%;
  height: 8dvh;
  width: 8dvh;
  transform: translate(-50%, -50%);
  animation-name: wiggle;
  animation-duration: 10s;
  animation-delay: 0s;
  animation-iteration-count: infinite;
}

@keyframes wiggle {
  40%,
  60% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  45% {
    transform: translate(-50%, -50%) rotate(5deg);
  }
  50% {
    transform: translate(-50%, -50%) rotate(-5deg);
  }
  55% {
    transform: translate(-50%, -50%) rotate(5deg);
  }
}

.App-link {
  color: #61dafb;
}

.Level-label {
  color: white;
  font-weight: 600;
  font-size: 7dvh;
  text-shadow: 0.3svh 0.3svh 0.6svh rgba(0, 0, 0, 0.5);
}

.Welcome-label {
  color: white;
  font-weight: 400;
  text-shadow: 0.3svh 0.3svh 0.6svh rgba(0, 0, 0, 0.5);
  margin: 2dvh;
}

.navigation-button {
  position: absolute;
  top: 50%;
  background: none;
  border: none;
  font-size: 7dvh;
  color: #fff;
  cursor: pointer;
  text-shadow: 0.3svh 0.3svh 0.6svh rgba(0, 0, 0, 0.5);
}
